import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom/cjs/react-router-dom.min'
import getApiCall from '../../Services/getApiCall';
import Timer from "../../Components/Timer";
import {formatDate, getDateTime, propertyTitle} from "../../Services/helpers";
import axios from "axios";
import Constant from "../../Components/Constant";
import loginUser from "../../Services/loginUser";

 
function PropertyDetail(props) {
    let {accessToken} = loginUser();

    const socket = props.socket;
    const history = useHistory();
    const [details, setDetails] = useState()
    const [bid, setBids] = useState([]);
    const [isFound, setIsFound] = useState(true);
    const [openBid, setOpenBid] = useState("NA")
    const [highestBid, setHighestBid] = useState("")
    const [isFullBSPaidBid, setIsFullBSPaidBid] = useState(false)
    const [isHighestBidderId,setIsHighestBidderId] = useState('')
    const [propertyReservePrice,setPropertyReservePrice] = useState('')
    const [financialInstituteName,setFinancialInstituteName] = useState('')
    const [propertyAddress,setPropertyAddress] = useState('')
    const [joinRoom, setJoinroom] = useState(false);
    const [connected, setConnected] = useState(socket.connected)
    const [time, setTimmer] = useState({})
    const [bidder, setBidder] = useState([])
    const [hblFileName, setHblFileName] = useState("")
    const [showPopup, setShowPopup] = useState(false);
    const [hoverText,setHoverText] = useState('')

    let {id} = useParams();

async function getbidderDetails(id){
  let data = await getApiCall(`user/property/${id}/bidders/list`);
  let records = []
  if (data.meta.status == true) {
    setFinancialInstituteName(data.data.partnerData.name)
    if (data.data.propertyBidderDetails.length >= 1) {
      setIsFullBSPaidBid(data.data.propertyBidderDetails.filter((bidder)=>bidder.isFullBSPaid == true)?.length>0?true:false)
     records =   data.data.propertyBidderDetails.map((item, index) => {
                let status = '';
                if (item?.isVerified) {
                    status = "Accepted"
                } else if (!item?.isVerified && !item?.bidderRejectReason) {
                    status = 'Pending';
                } else if (!item?.isVerified && item?.bidderRejectReason) {
                    status = 'Rejected';
                }
               
                setHblFileName(`${data.data.propertyDetails.auctionId}_${data.data.propertyDetails.propertyId}_hbl`)
                return <tr key={index}>
                    <td>{(index + 1)}</td>
                    <td>{item.bidderId.createdAt ? item.bidderId.createdAt.split("T")[0]: 'NA'}</td>
                    <td><a href={`/bidder/bidder_overviews/${item.participationId}`}>{item.participationId !== undefined ? item.participationId : "-"}</a></td>
                    <td>{item.bidderId.type}</td>
                    <td>{item.bidderId.type != "COMPANY" ? item.bidderId.name: item.bidderId.companyName}</td>
                    <td>{item.bidderId.email}</td>
                    <td>{item.bidderId.mobile}</td>
                    <td>{item.totalPaidAmount}</td>
                    <td>{data.data.propertyDetails.price}</td>
                    <td>{item.isSigned == true ? "Done": "Pending"}</td>
                    <td>Success</td>
                      
                </tr>
        })
        setBidder(previous => [...previous, records]); 
    }else{
        records = <tr><td colspan={15} style={{textAlign: 'center'}}>No Records</td></tr>
        setBidder(previous => [...previous, records]); 
    }
}else{
    records = <tr><td colspan={15} style={{textAlign: 'center'}}>No Records</td></tr>
    setBidder(previous => [...previous, records]);
}

}
    async function getPropertyDetailsById(id) {
        setIsFound(false)
        let response = await getApiCall('user/property/getDetailsById/' + id);
        if (response.meta.status) {
            setDetails(response.data);
        }

        socket.on('connect_error', (error) => {
            setTimeout(() => socket.connect(), 5000)
        });
       
        socket.on('disconnect')
    }

    async function RoomJoinInit(id) {
        if (joinRoom == false) {
            await socket.on('connect');
            await socket.emit('join_room', {'propertyId': id});
            setJoinroom(true)

        } else {
         //   console.log("room already joined")
        }
    }

    function truncateText(value) {
      let truncated = value.length < 50 ? value :value.substring(0, 50) + "...";
      return truncated;
    }
  
    function handleMouseOver(e){
      if(e.length < 50){
        return 
      }
      setHoverText(e)
    }
    function renderBidList() {
        history.push(`/liveBidderList/${id}`)
    }
    async function downloadExcel() {
        let response = await getApiCall(`user/bid/bidder/letter/644cd52378fa9a3ef4981d20`);
        if (response.meta.status) {
            // console.log(response.data)
            var csvString = response.data;
            var universalBOM = "\uFEFF";
            var a = window.document.createElement('a');
            a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvString));
            a.setAttribute('download', 'exportLeadList.csv');
            window.document.body.appendChild(a);
            a.click();
            // window.location.reload();
        }

    }

    async function downloadPDF2() {
        let response = await getApiCall(`user/bid/bidder/letter/644cd52378fa9a3ef4981d20`);
        if (response.meta.status) {
            var pdfData = response.data;
            // Create a Blob from the PDF data
            var blob = new Blob([pdfData], {type: 'application/pdf'});

            // Create a temporary link element to trigger the download
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'exportLeadList.pdf';
            document.body.appendChild(link);
            link.click();
            // document.body.removeChild(link);
        }
    }

    async function downloadPDF3() {
        let response = await getApiCall(`user/bid/bidder/letter/644cd52378fa9a3ef4981d20`);
        if (response instanceof Blob) {
            // Create a temporary link element to trigger the download
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(response);
            link.download = 'exportLeadList.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
    async function goBackPage(){
      window.location.href = '/live_bid';
    }

    async function downloadPDF() {
        try {
            const config = {
                headers: {
                    authkey: accessToken
                },
                responseType: 'blob'
            };
            const response = await axios.get(Constant.apiBasePath + 'user/bid/bidder/letter/' + details?._id, config);
            const pdfData = response.data;

            // Create a Blob from the PDF data
            const blob = new Blob([pdfData], {type: 'application/pdf'});

            // Create a temporary link element to trigger the download
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = hblFileName+'.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            // Handle any errors
            console.error('Error downloading PDF:', error);
        }
    }


    useEffect(() => {
        RoomJoinInit(id)
        getPropertyDetailsById(id)
        getbidderDetails(id)
        
        // update records
        function updateBids(data) {
            let records = ""
            if (data.meta.status == true) {
                let items = data?.data
                items = items ? items.filter((item) => {
                    return item.isPrebid == false
                }) : []
                if (items.length >= 1) {
                    setOpenBid(items[items?.length - 1].message)
                    setHighestBid(items[0]?.message)
                    setIsHighestBidderId(items[0]?.bid._id)
                    setPropertyReservePrice(items[0]?.bid?.offerAmount)
                    setPropertyAddress(items[0]?.properties?.address)
                    records = items.map((rec, index) => {
                        return (<tr key={`${rec.bidder.bidderId}_${index}`}>
                            <td>{rec.message}</td>
                            <td>{rec.bidder.bidderId ?? '-'}</td>
                            <td>{getBidDate(rec.createdAt)}</td>
                        </tr>);
                    })
                    setBids(records);
                    setIsFound(true)
                } else {
                    records = <tr><td>No bid</td></tr>
                    setBids(records);
                    setIsFound(false)
                }
            } else {
                records = <tr><td>No bid</td></tr>
                setBids(records);
                setIsFound(false)
            }
        }

   
        
        // add connect of socket
        function addConnect(){
          setConnected(true)
        }

        // check & update socket status
        function updateConnection(){
          setConnected(false)
        }

        socket.timeout(5000).on('get_chat_admin',updateBids);
       // socket.timeout(5000).on('get_top_bidder',updateBidder);

          return () => {
            socket.off('connect', addConnect);
            socket.off('disconnect', updateConnection);
            socket.off('get_chat_admin', updateBids);
          };

    }, [])


  function getBidDate(dateString){
    const date = new Date(dateString);
    const formatDate = date.toLocaleDateString('en-IN', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true, 
      });
      return formatDate
 }
    
  async function loadSaleCertificate() {
    let bidderId = isHighestBidderId; //to be ask 
    window.open(`${Constant.apiBasePath}common/sale/admin/letter/${bidderId}`);
    // setSaleCertificate(`${Constant.apiBasePath}common/sale/admin/letter/${bidderId}`);
}

    return (
        <>
            <div className="propertyDetails mt-4 container-fluid">
                <div className="main-title">
                    <h3>Auction Details</h3>
                </div>
                <div className="d-flex flex-row justify-content-between w-100">
                    <div className=' text-left'>
                        <button type="submit" className="btn btn-warning" onClick={() => goBackPage()}>Go
                            Back
                        </button>
                    </div>
                    <div className='text-left'>
                    <b><span>NIT Ref No. </span>{"  :-  "}
                    {financialInstituteName + " Vs " + details?.borrowerName} 
                    </b>  
                   <p></p>
                    </div>
                    <div className='d-flex flex-row justify-content-between' style={{ width: '28%'}}>
                    <button className="btn btn-primary" type={"button"}
                            onClick={() => downloadPDF()} disabled={details?.isAwarded === true ? false : true}
                            >Download HBL
                        </button>
                    <button className="btn btn-primary" style={{ border: '1px solid'}} disabled={!isFullBSPaidBid && details?.soldStatus == "NO"} onClick={() => loadSaleCertificate()}>Download Sale Certificate</button>
                    </div>
                    
                </div>

                <div className="card h-100 py-2 mt-2">
                    <div className="card-body">
                        <div className='d-flex flex-row justify-content-between'>
                        <div style={{ width:'120%',marginTop:'0.75rem'}}>
                            <div className="">
                                {
                                    isFound ?
                                        <div className="table-responsive" style={{ maxHeight:'320px'}}>
                                               <div className="col-md-12 col-12" style={{ background:"#242056", textAlign:'center',height:'2rem'}}>
                              <div className="dec-text">
                              <p>
                                <strong style={{ color: '#fff',fontSize:'14px'}}>Bid Summary</strong> 
                                  </p>
                              </div>
                            </div>
                                            <table className="table table-bordered table-striped">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Bidders ID</th>
                                                    <th scope="col">Time</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {bid}
                                                </tbody>
                                            </table>

                                        </div>
                                        : <>
                                        <h5 className={'text-center'}>No Bid Found</h5>
                                        </>
                                }

                            </div>
                        </div>
                        <div className="card-body">
                          <div className="row" style={{fontSize:'12px'}}>
                          <div className="col-md-12 col-12" style={{ background:"#242056", textAlign:'center',height:'2rem'}}>
                              <div className="dec-text">
                              <p style={{ marginTop:'0.3rem'}}>
                                <strong style={{ color: '#fff',fontSize:'14px'}}>Property Details</strong> 
                                  </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-12 border">
                              <div className="dec-text">
                              <p>
                                <strong>Prospect Number :</strong> 
                                {details?.prospectId}
                                  </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-12 border">
                            <div className="dec-text">
                              <p>
                                <strong>Property Title :</strong>{" "}
                                {propertyTitle(details)}
                              </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-12 border">
                            <div className="dec-text">
                              <p>
                                <strong>Property Address :</strong>{" "}
                               <td className="new-tooltip1" onMouseOver={()=> handleMouseOver(details?.address)}>{details?.address&&truncateText(details?.address)}
                            {
                              details?.address?.length > 50 ?
                              <span>{hoverText}</span>
                              :<></>
                            }
                           
                            </td>
                              </p>
                              </div>
                              </div>
                            
                            <div className="col-md-4 col-12 border">
                              <div className="dec-text">
                              <p>
                                <strong>Borrower Name:</strong> 
                                {details?.borrowerName}
                              </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-12 border">
                             <div className="dec-text">
                              <p>
                                <strong>Institution Name:</strong>{" "}
                                {financialInstituteName}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-12 border">
                            <div className="dec-text">
                              <p>
                                <strong>Location:</strong>{" "}
                                {details?.cityName} {", "}  {details?.stateName}
                              </p>
                              </div>
                            </div>
                           
                            <div className="col-md-12 col-12" style={{ background:"#242056", textAlign:'center',height:'2rem'}}>
                              <div className="dec-text">
                              <p style={{ marginTop:'0.3rem'}}>
                                <strong style={{ color: '#fff',fontSize:'14px'}}>Price</strong> 
                                  </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-12 border">
                            <div className="dec-text">
                              <p>
                                <strong>Reserve Price :</strong>{" "}
                              {details?.price}
                               </p>
                              </div>
                            </div>
                         
                            <div className="col-md-4 col-12 border">
                            <div className="dec-text">
                              <p>
                                <strong>Bid Closing Amount :</strong>{" "}
                                {highestBid}
                               </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-12 border">
                            <div className="dec-text">
                              <p>
                                <strong>Bid Increment Amount:</strong>{" "}
                                {details?.bidIncrementAmount}
                               </p>
                              </div>
                            </div>
                            <div className="col-md-12 col-12" style={{ background:"#242056", textAlign:'center',height:'2rem'}}>
                              <div className="dec-text">
                              <p style={{ marginTop:'0.3rem'}}>
                                <strong style={{ color: '#fff',fontSize:'14px'}}>Auction Dates</strong> 
                                  </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-12 border">
                            <div className="dec-text">
                              <p>
                                <strong>Auction Date:</strong>{" "}
                                {details?.auctionStartDateTimeEpoch ? getDateTime(details.auctionStartDateTimeEpoch)?.date.toLocaleDateString() : ''}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-12 border">
                            <div className="dec-text">
                              <p>
                                <strong> Auction Start Time to Auction End Time:</strong>{" "}
                                {details?.auctionStartTime +'-'+ details?.auctionEndTime}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-12 border">
                            <div className="dec-text">
                              <p>
                                <strong>EMD Submition Date:</strong>{" "}
                                {details?.EMDLastPaymentDate}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      
                       
                        </div>

                        <div style={{ background:"#242056", textAlign:'center',width:'99%',height:'2rem'}}>
                              <div className="dec-text">
                              <p >
                                <strong style={{ color: '#fff'}}>Bidders</strong> 
                                  </p>
                              </div>
                            </div>

                        <div className="table-responsive">
                            <table className="table table-bordered" width="100%" cellSpacing="0" style={{display: 'block', overflowX: 'auto', whiteSpace: 'nowrap'}}>
                                <thead>
                                <tr style={{ backgroundColor: 'rgba(0,0,0,.05)'}}>
                                    <th>S No.</th>
                                    <th>Registration Date</th>
                                    <th>Participation ID</th>
                                    <th>Bidder Type</th>
                                    <th>Bidder Name</th>
                                    <th>Bidder Email</th>
                                    <th>Bidder Mobile</th>
                                    <th>EMD Amount</th>
                                    <th>Reserve Price</th>
                                    <th>E-sign Status</th>
                                    <th>Payment Status</th>
                                     
                                </tr>
                                </thead>

                                <tbody>
                                        {bidder}
                                </tbody>

                                </table>
                                </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PropertyDetail