import Axios from "axios";
import Constant from "../Components/Constant";
import { userDetails} from "../Services/authenticationService";

export default function useGetRoleModule(route) {
    let userDet = userDetails();
    let urlParms = userDet.partnerDetails._id == undefined ? `role=${userDet.role}&route=${route}` : `userId=${userDet?.partnerDetails._id}&role=${userDet.role}&route=${route}`
   
   return new Promise((resolve, reject) => {
       Axios.get(Constant.apiBasePath + `admin/permission/modules/partnerByRole?${urlParms}`, {headers: {authkey: userDet.token, sslpinning: Constant.SSL_PINNING}},{})
        .then(data => {
            if (!data.data.meta.status) {
                resolve({moduleAccress : false, moduleList:{}, message:`Module Need Some Permission...Pls contact with Your ${userDet.role == "partner" ? "Admin" : "Partner"}`, role: userDet.role});
            }
            let permission = data.data.data;
           permission = data.data.data.join("W")
            permission = window.atob(permission)
            permission = JSON.parse(permission)
            permission = permission.join("Q")
            permission = window.atob(permission)
            permission = JSON.parse(permission)
            // if(permission.read != undefined){
                if(userDet.role == "admin"){
                    permission = permission[0]
                }else{
                    permission = {
                      ...permission,
                      createDisabled: !permission.create,
                      readDisbled: !permission.read,
                      updateDisabled: !permission.update,
                      deleteDisabled: !permission.deleted,
                    }
                }
                  if(permission.read == false && permission.create == false && permission.update == false && permission.deleted == false){
                resolve({moduleAccress : false, moduleList:{}, message:`Module Need Some Permission...Pls contact with Your ${userDet.role == "partner" ? "Admin" : "Partner"}`, role: userDet.role});
            }else{
                resolve({moduleAccress : true, moduleList: permission, message:"Module Allow To Access", role: userDet.role});
            }
        // }else{
        //     console.log("sdsdsd")
        //     resolve({moduleAccress : false, moduleList:{}, message:"Module Need Some Permission...Pls contact with Your Partner"});

        // }
           
        })
        .catch(err => {
            if(err.response.status === 401){
                sessionStorage.clear();
                window.location.href = '/';
                return false;
            }
            console.log('GET ROLE MOUDLE IN CATCH BLOCK', err.response);
            resolve({moduleAccress : false, moduleList:{}, message:"Module In Catch Block"});
        })

   })

}


