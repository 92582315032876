import Header from "./Layout/Header";
import {Logout, userDetails} from "./Services/authenticationService";
import {useEffect, useState} from "react";
import {ToastContainer} from 'react-toastify';
import {Link} from 'react-router-dom';
import getApiCall from "../src/Services/getApiCall";

export default function Layout(props) {
    // console.log("layout",props)
    useEffect(async () => {
        const userDetailsData = userDetails()
    }, []);


    return <>
        <div id="wrapper">
            <Header/>
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">

                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                            <i className="fa fa-bars"></i>
                        </button>
                       
                        <ul className="navbar-nav ml-auto">
                            {/* <div className="topbar-divider d-none d-sm-block"></div> */}
                            <li style={{ textDecoration:'none'}}>
                            <Link to={"/properties/upload_media"}
                      className="d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-4">Upload Media</Link>
                            </li>
                            <li className="nav-item dropdown no-arrow">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img className="img-profile rounded-circle"
                                         src="https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?size=338&ext=jpg"
                                         alt="user"/> <span>{userDetails().name} </span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                     aria-labelledby="userDropdown">

                                    {/* <p className="p-1">Name - {userDetails().name} </p> */}
                                    <p className="dropdown-item"><strong>Role </strong>- {userDetails().role} </p>

                                    {userDetails().role !== "admin" ?
                                        <>
                                            <p className="dropdown-item"><strong>Partner
                                                Name </strong> - {userDetails().partnerDetails ? userDetails().partnerDetails.name : ''}
                                            </p>
                                            <p className="dropdown-item"><strong>Service
                                                Type</strong> - {userDetails().serviceType === 2 ? "Auction Property" : "Property Listing"}
                                            </p>

                                        </>
                                        :
                                        ""}
                                    <p className="dropdown-item"><strong>Mobile </strong> - {userDetails().mobile} </p>
                                    <p className="dropdown-item"><strong>Email</strong> - {userDetails().email} </p>
                                    <div className="dropdown-divider"></div>
                                    <button className="dropdown-item" onClick={Logout}>
                                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Logout
                                    </button>
                                </div>
                            </li>
                        </ul>

                        {/* <span className="mr-2 d-none d-lg-inline text-gray-600 small">Name <br/>{userDetails().name} </span>
                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">Partner Name <br/>{userDetails().partnerDetails ? userDetails().partnerDetails.name : '' } </span>
                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">Role <br/> {userDetails().role} </span>
                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">Service Type <br/> {userDetails().serviceType === 2 ? "Auction Property" : "Property Listing"} </span> */}
                        {/*<span className="mr-2 d-none d-lg-inline text-gray-600 small">Mobile <br/> {userDetails().mobile} </span>*/}
                        {/*<span className="mr-2 d-none d-lg-inline text-gray-600 small">Email <br/> {userDetails().email} </span>*/}


                    </nav>
                 
                    {/* {props.children.map( rec => {
                        return {...rec, props: { ...rec.props, socket : props.socket.socket}}
                    })
                } */}
                    
                        {props.children}

                </div>


                <footer className="sticky-footer bg-white">
                    <div className="container my-auto">
                        <div className="copyright text-center my-auto">
                            <span>Copyright &copy; IIFL 2022</span>
                        </div>
                    </div>
                </footer>


            </div>


        </div>

        <a className="scroll-to-top rounded" href="#page-top">
            <i className="fas fa-angle-up"></i>
        </a>
        <ToastContainer/>
    </>
}