import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import postApiCall from "../../Services/postApiCall";
import PaginationNew from "../../Widgets/PaginationNew";
import Constant from "../../Components/Constant";
import useGetRoleModule from "../../Services/useGetRoleModule";

const CityListing = () => {
  const [lists, setLists] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [perPage, setPerPage] = useState(Constant.perPage);
  const [isLoaded, setIsLoaded] = useState(true);
  const [permission, setPermission] = useState({});

  const [filterForm, setFilterForm] = useState({
    // status: '',
    searchKey: "",
    // 'type': '',
  });

  const onChangeFilter = (e) => {
    if (!e.target.name) return;
    setFilterForm({
      ...filterForm,
      [e.target.name]: e.target.value,
    });
  };
  const submitFilter = (e) => {
    e.preventDefault();
    getUser();
  };

  function pageChangeHandler(page) {
    setPageNo(page);
  }

  useEffect(() => {
    getUser();
  }, [pageNo]);

  async function GetRole() {
    let Role = await useGetRoleModule("cities");
    if (Role.moduleAccress) {
      setPermission(Role);
      getUser();
    }else{
        setPermission(Role)
    }
  }

  function getUser() {
    // getApiCall('admin/user/list', {
    //     page: pageNo,
    //     contentPerPage: perPage,
    //     ...filterForm
    // })
    // postApiCall('admin/city/getAll', {searchText: searchTxt, filter: filter, page: page})
    postApiCall("admin/city/getAll", {
      searchKey: filterForm.searchKey,
      filter: "",
      page: pageNo,
    })
      .then((response) => {
        if (response.meta.status) {
          setLists(response.data.docs);
        } else {
          setLists([]);
        }
        setIsLoaded(true);
        setTotalItems(response.data.length);
      })
      .catch((error) => {
        setIsLoaded(true);
      });
  }

  useEffect(() => {
    GetRole();
  }, []);

  function UpdateStatus(e) {
    let id = e.currentTarget.getAttribute("value");
    let status = e.currentTarget.getAttribute("status");
    let isDeleted = e.currentTarget.getAttribute("isDeleted");
    status = status === "DEACTIVE" ? "active" : "deactive";
    postApiCall("user/property/status", {
      _id: id,
      status: status,
      // isDeleted: isDeleted
    }).then((response) => {
      if (response.meta.status) {
        swal({ text: response.meta.msg, icon: "success", timer: 1500 });
        getUser();
      }
    });
  }

  return (
    <>
      <div className="container-fluid">
        <div className="main-title">
          <h3>Cities</h3>
        </div>
        {permission.hasOwnProperty("moduleAccress") &&
        !permission.moduleAccress ? (
            <div className="row text-center">
            <div className="col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
              <div className="errer">
                <img src="/program-error.png" />
                <h2>403</h2>
                <p>{permission.message}</p>
              </div>
            </div>
          </div>
        ) : Object.keys(permission).length > 0 ? (
          <>
            <div className="d-sm-flex align-items-center justify-content-end mb-4">
              {permission.moduleList.createDisabled ? null : (
                <Link
                  to="/add-city/"
                  className="d-sm-inline-block btn btn-sm btn-warning shadow-sm"
                >
                  Add New
                </Link>
              )}
            </div>
            <div className="card shadow mb-2">
              <div className="card-header">
                <h5 className="card-title mb-0">Search and Filter</h5>
              </div>
              <div className="card-body">
                <form onSubmit={submitFilter} onChange={onChangeFilter}>
                  <div className="row">
                    <div className="col-12 col-xs-3 col-md-3 col-lg-3">
                      <div className="form-group">
                        <label>Search </label>
                        <input
                          type="text"
                          className="form-control"
                          name="searchKey"
                          value={filterForm.searchKey}
                        />
                      </div>
                    </div>

                    {/*<div className="col-12 col-xs-3 col-md-3 col-lg-3">*/}
                    {/*    <div className="form-group">*/}
                    {/*        <label>Status</label>*/}
                    {/*        <select className="form-control" name="status" value={filterForm.status}>*/}
                    {/*            <option value="">Select</option>*/}
                    {/*            <option value="active">Active</option>*/}
                    {/*            <option value="deactivate">Deactivate</option>*/}
                    {/*        </select>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="col-12 col-xs-3 col-md-3 col-lg-3">*/}
                    {/*    <div className="form-group">*/}
                    {/*        <label>Type</label>*/}
                    {/*        <select className="form-control" name="type" value={filterForm.type}>*/}
                    {/*            <option value="">All</option>*/}
                    {/*            <option value="ROOT">Root</option>*/}
                    {/*        </select>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="col-12 col-xs-3 col-md-3 col-lg-3">
                      <div className="form-group mt30">
                        <button
                          type="submit"
                          className="btn btn-md btn-warning shadow-sm  mr-2"
                        >
                          {" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="table-responsive">
                  <table
                    className="table table-bordered"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th>Sl. No.</th>
                        <th>City name</th>
                        <th>State name</th>
                        {/*<th>Status</th>*/}
                        {permission.moduleList.deleteDisabled &&
                        permission.moduleList.updateDisabled ? null : (
                          <th>Action</th>
                        )}
                      </tr>
                    </thead>

                    <tbody>
                      {lists.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1 + (pageNo - 1) * 10}</td>
                            <td>{item.name}</td>
                            <td>{item.stateName}</td>
                            {/*<td>{item.status ? 'Active' : 'Inactive'}</td>*/}
                            <td>
                              {/*
                                                        {permission.moduleList.updateDisabled ? null :
                                                            !item.status ?
                                                                <button
                                                                    onClick={UpdateStatus}
                                                                    value={item._id}
                                                                    status={item.status}
                                                                    className="btn btn-info btn-icon-split btn-sm  mb-1 mr-1">
                                                        <span
                                                            className="icon text-white-50"><i
                                                            className="fas fa-exclamation-triangle"></i></span>
                                                                    <span className="text">Disable</span>
                                                                </button>
                                                                :
                                                                <button
                                                                    className="btn btn-success btn-icon-split btn-sm mb-1 mr-1"
                                                                    onClick={UpdateStatus} value={item._id}
                                                                    status={item.status}
                                                                >
                                                    <span className="icon text-white-50"><i
                                                        className="fas fa-check"></i></span>
                                                                    <span className="text">Enable</span>
                                                                </button>
                                                        }
                                                        */}

                              {permission.moduleList.updateDisabled ? null : (
                                <Link
                                  to={`/edit-city/${item._id}`}
                                  className="btn btn-primary btn-icon-split btn-sm mb-1 mr-1"
                                >
                                  <span className="icon text-white-50">
                                    <i className="far fa-edit"></i>
                                  </span>
                                  <span className="text">Edit</span>
                                </Link>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="overflow-auto mt-2">
                    <PaginationNew
                      perPage={perPage}
                      totalItems={totalItems}
                      currentPage={pageNo}
                      handler={pageChangeHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default CityListing;
