import React, {useEffect, useState} from "react";
import postApiCall from "../Services/postApiCall";
import swal from "sweetalert";
import {getAccessToken} from "../Services/AccessToken";
import {toast, ToastContainer} from "react-toastify";
import {decryptBody} from "../Services/helpers"

export default function Login() {
    const [typeState, setType] = useState(2);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [step, setStep] = useState('login');
    const [otp, setOtp] = useState('');
    const [partnerUserId, setPartnerUserId] = useState('');
    const [isReady, setIsReady] = useState(true);

    useEffect(() => {
        let token = getAccessToken();
        if (token) window.location.href = '/dashboard';
    }, []);

    useEffect(() => {
        //auto make true after 30
        if (!isReady) {
            setTimeout(() => {
                setIsReady(true)
            }, 5000)
        }
    }, [isReady])


    useEffect(() => {
        setEmail('')
        setPhone('')
    }, [typeState]);

    //on change email
    const onChangeEmail = (e) => {
        if (typeState === 1) {
            setEmail(e.target.value);
        } else {
            const re = /^[0-9\b]+$/;
            if (e.target.value === '' || re.test(e.target.value)) {
            //    this.setState({value: e.target.value})
               setPhone(e.target.value);
            }else{
                window.alert("only number allow")
            }
            
        }
    }
    const onChangeOtp = (e) => {
        setOtp(e.target.value);
    }
    const sendOtp = (e) => {
        e.preventDefault()
        if (!isReady) return alert('Already OTP sended')
        setIsReady(false)
        let obj = {
            type: typeState
        }
        if (typeState === 1) {
            obj.email = email
        } else {
            obj.mobile = phone
        }
        let decryptUserId = ''
        postApiCall('admin/sendOTP', obj)
            .then(data => {
                if (data.meta.status) {
                    
                    decryptUserId =  decryptBody(data.data.encryptedData)
                    setPartnerUserId(decryptUserId.userId)
                    setStep('otp')
                    setIsReady(true)
                   // if (data.otp) toast('OTP is ' + data.otp)
                    // if (data.otp) setOtp(data.otp)
                }
            })
            .catch(err => {
                setIsReady(true)
            })
    }

    const changePhone = () => {
        setStep('login')
    }

    const verifyOtp = (e) => {
         e.preventDefault()
        postApiCall('admin/verifyOTP', {partnerUserId:partnerUserId, otp: otp,type:typeState}) //votp
            .then(data => {
                if (data.meta.status) {
                    // console.log('loginDetails', data);
                    sessionStorage.setItem('loginDetails', data.data.token);
                    sessionStorage.setItem('userDetails', JSON.stringify(data.data));
                    window.location.href = '/dashboard';
                } else {
                    swal({text: data.meta.msg, icon: "warning", dangerMode: true});
                }
            })
    }

    return <>
        <div className="container">
            <div className="row justify-content-center align-items-center">
                <div className="col-12 col-sm-12 col-md-8 col-lg-6">


                    <ToastContainer/>
                    <div className="card card-login mx-auto mt-5 align-self-center">
                        {
                            step === 'login' ?
                                <>
                                <form onSubmit={(e) => sendOtp(e)}>
                                    <div className="card-body">
                                        <div className="text-center">
                                            <img src="/logo.png" className="mx-auto" alt={''} width={100}/>
                                        </div>
                                        <div className="title mb-5 text-center">
                                            <h4>Login</h4></div>
                                        <div className="form-group">
                                            <div className="row mb-4">
                                                <div className="col-6">
                                                    <button type="button"
                                                            className={typeState === 2 ? "btn btn-primary  btn-block" : "btn btn-secondary   btn-block"}
                                                            onClick={() => setType(2)}> Login with Phone
                                                    </button>
                                                </div>
                                                <div className="col-6">
                                                    <button type="button"
                                                            className={typeState === 1 ? "btn btn-primary  btn-block" : "btn btn-warning  btn-block"}
                                                            onClick={() => setType(1)}> Login with Email
                                                    </button>
                                                </div>
                                            </div>
                                            {
                                                typeState === 2 ?
                                                    <div className="form-label-group form-group mb-4">
                                                        <input type="text" id="inputEmail" className="form-control"
                                                               placeholder="Enter Phone Number"
                                                               value={phone}
                                                               maxLength="10" pattern="[0-9]{10}" title="Please enter exactly 10 digits"
                                                               required="required" autoFocus="autofocus"
                                                               onChange={onChangeEmail}/>
                                                    </div>
                                                    :
                                                    <div className="form-label-group form-group mb-4">
                                                        <input type="email" id="inputEmail" className="form-control"
                                                               placeholder="Enter Email address"
                                                               value={email}
                                                               required="required" autoFocus="autofocus"
                                                               onChange={onChangeEmail}/>
                                                    </div>
                                            }
                                            {
                                                email || phone ?
                                                    <button 
                                                    className="btn btn-warning btn-block mt-2"
                                                    onClick={sendOtp}
                                                    type="submit"
                                                    >Get OTP</button>
                                                    :
                                                    <button className="btn btn-warning btn-block mt-2">Get OTP</button>
                                            }

                                        </div>

                                    </div>
                                    </form>
                                </>
                                :
                                <>
                                <form onSubmit={(e) => verifyOtp(e)}>
                                    <div className="card-body">
                                        <div className="title mb-5 text-center">
                                            <h4>Verify OTP</h4></div>
                                        <div className="row">
                                            <div className="col-10">
                                                <div className="form-group">
                                                    {email ? <input type="text" value={email} className="form-control" disabled={true} /> : '' }
                                                    {phone ? <input type="text" value={phone} className="form-control" disabled={true} /> :''}
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <span className="btn btn-link" onClick={changePhone}>Change</span>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-label-group mb-4">
                                                <input type="number" id="inputEmail" className="form-control"
                                                       placeholder="Enter OTP"
                                                       value={otp}
                                                       required="required" autoFocus="autofocus"
                                                       onChange={onChangeOtp}/>
                                            </div>

                                            {
                                                otp ?
                                                    <button 
                                                    className="btn btn-primary btn-block mt-2 test"
                                                    onClick={verifyOtp}
                                                    type="submit"
                                                    >Verify</button>
                                                    :
                                                    <button className="btn btn-warning btn-block mt-2" type="submit">Verify</button>
                                            }

                                        </div>

                                    </div>
                                    </form>
                                </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}