import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import getApiCall from "../../Services/getApiCall";

import swal from "sweetalert";
import patchApiCall from "../../Services/patchApiCall";
import deleteApiCall from "../../Services/deleteApiCall";
import Constant from "../../Components/Constant";
import PaginationNew from "../../Widgets/PaginationNew";
import {shortText} from "../../Services/helpers";
import useGetRoleModule from '../../Services/useGetRoleModule';
var slugify = require('slugify');

const BlogListing = () => {
    const [lists, setLists] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [perPage, setPerPage] = useState(Constant.perPage);
    const [isLoaded, setIsLoaded] = useState(true);
    const [permission, setPermission] = useState({})
    const [search,setSearch] = useState('')

    function pageChangeHandler(page) {
        if (isLoaded) {
            setPageNo(page);
        }
    }

    useEffect(() => {
        GetRole()
    }, [pageNo])

    async function getList() {
        let response
        if(search != ''){
             response = await getApiCall('common/blog/get-all', {
                page: pageNo,
                contentPerPage: perPage,
                searchKey: search
            });
        }else{
             response = await getApiCall('common/blog/get-all', {
                page: pageNo,
                contentPerPage: perPage,
            });
        }
      
        setLists(response.data)
        setTotalItems(response.total)
    }

    async function GetRole() {
        let Role = await useGetRoleModule("blogs");
        if(Role.moduleList.read === false){
            setPermission({moduleAccress : false, moduleList:{}, message:"Module Need Some Permission...Pls contact with Your Partner"})
        }else{
            setPermission(Role)
            getList();
        }
        
    }

    useEffect(() => {
        GetRole()
    }, []);

    useEffect(()=>{
getList()
    },[search])

    function UpdateStatus(e) {
        let id = e.currentTarget.getAttribute('value');
        let status = e.currentTarget.getAttribute('status');
        status = status === "DEACTIVE" ? "ACTIVE" : "DEACTIVE"
        patchApiCall('common/blog/changeStatus/' + id, {
            status: status,
        }).then((response) => {
            if (response.meta.status) {
                swal({text: response.meta.msg, icon: "success", timer: 1500})
                getList();
            }
        });
    }

    const handleChange =(e)=>{
       setSearch(e)
    }

    function DeleteEvent(e) {
        let id = e.currentTarget.getAttribute('value');
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteApiCall('common/blog/delete/' + id, {}).then((response) => {
                    if (response.meta.status) {
                        swal({text: response.meta.msg, icon: "success", timer: 1500})
                        getList();
                    }
                });
            } else {
                // swal("Your imaginary file is safe!");
            }
        });

    }


    return (
        
        <>
            <div className="container-fluid">
            {permission.hasOwnProperty('moduleAccress') && !permission.moduleAccress ?
                    <div className="row text-center">
                        <div className="col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                            <div className="errer">
                                <img src="/program-error.png"/>
                                <h2>403</h2>
                                {/* <h4 className="text-danger">{permission.message}</h4> */}
                                <p>{permission.message}</p>

                            </div>
                        </div>
                    </div>
                    :
                    (Object.keys(permission).length > 0) ?
                <>
                <div className="main-title"><h3> Blogs</h3></div>
                <div className="d-sm-flex align-items-center justify-content-end mb-4">
                    
                    {permission.moduleList.createDisabled ? null :<Link to="/blogs/add" className="d-sm-inline-block btn btn-sm btn-warning shadow-sm">
                        Add New
                    </Link> }
                </div>
                <div className="card shadow mb-4">
                    <div className="card-body">
                    <div className="col-12 col-xs-3 col-md-3 col-lg-3">
                                                    <div className="form-group">
                                                        <label>Search </label>
                                                        <input type="text" className="form-control" name="searchKey"
                                                            value={search} onChange={(e)=>handleChange(e.target.value)}
                                                            placeholder="Search By Blog Name ..."
                                                        />
                                                    </div>
                                                </div>
                        <div className="table-responsive">
                            <table className="table table-bordered" width="100%" cellSpacing="0">
                                <thead>
                                <tr>
                                    <th>Sl. No.</th>
                                    <th>Title/Header</th>
                                    <th>Author</th>
                                    <th>Author Designation</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    {permission.moduleList.deleteDisabled && permission.moduleList.updateDisabled ? null : <th>Action</th> }
                                </tr>
                                </thead>

                                <tbody>
                                {
                                 lists &&  lists.map((item, index) => {
                                        return <tr key={index}>
                                            <td>{(index + 1) + ((pageNo - 1) * 10)}</td>
                                            <td>{item.title}</td>
                                            <td>{item.postBy}</td>
                                            <td>{item.designation}</td>
                                            <td>{shortText(item.description)}</td>
                                            <td>{item.status}</td>
                                            {permission.moduleList.deleteDisabled && permission.moduleList.updateDisabled ? null : <td>
                                                {permission.moduleList.updateDisabled ? null : item.status == "DEACTIVE" ?
                                                    <button
                                                        onClick={UpdateStatus} value={item._id}
                                                        status={item.status}
                                                        className="btn btn-info btn-icon-split btn-sm  mb-1 mr-1"><span
                                                        className="icon text-white-50"><i
                                                        className="fas fa-exclamation-triangle"></i></span>
                                                        <span className="text">Disable</span>
                                                    </button>
                                                    :
                                                    <button className="btn btn-success btn-icon-split btn-sm mb-1 mr-1"
                                                            onClick={UpdateStatus} value={item._id}
                                                            status={item.status}
                                                    >
                                                    <span className="icon text-white-50"><i
                                                        className="fas fa-check"></i></span>
                                                        <span className="text">Enable</span>
                                                    </button>
                                                }
                                              {permission.moduleList.updateDisabled ? null :
//  slugify((blogtittle[blogtittle.length -1]), { replacement: '-', remove: /[*+~.()'"!:@]/g, lower: true,  strict: true, trim: true });
                                                <Link to={"blogs/add?" + slugify(item.title, { replacement: '-', remove: /[*+~.()'"!:@]/g, lower: true, strict: true, trim: true })}
                                                      className="btn btn-primary btn-icon-split btn-sm mb-1 mr-1">
                                                    <span className="icon text-white-50">
                                                        <i className="far fa-edit"></i>
                                                    </span>
                                                    <span className="text">Edit</span>
                                                </Link> }
                                                {permission.moduleList.deleteDisabled ? null :
                                                <button onClick={DeleteEvent} value={item._id} isDeleted={1}
                                                        className="btn btn-danger btn-icon-split btn-sm mb-1">
                                                        <span className="icon text-white-50">
                                                            <i className="far fa-eye"></i>
                                                        </span>
                                                    <span className="text">Delete</span>
                                                </button> }
                                            </td> }
                                        </tr>

                                    })
                                }
                                </tbody>
                            </table>
                            <div className="justify-content-center mt-2">
                                <PaginationNew perPage={perPage} totalItems={totalItems}
                                               currentPage={pageNo}
                                               handler={pageChangeHandler}/>
                            </div>
                        </div>
                    </div>
                </div></> : null}


            </div>
        </>
    )
}

export default BlogListing
